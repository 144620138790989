











































































































































































































/* eslint-disable */
import { api_login } from "@/api";
import { ElForm } from "element-ui/types/form";
import { Vue, Component } from "vue-property-decorator";
import PhoneSend from "../components/phoneSend.vue";
import { OtherModule } from "@/store/other";
@Component({
  components: {
    PhoneSend,
  },
})
export default class extends Vue {
  validatePhone(rule: any, value: string, callback: any) {
    const reg = /^(\d){3}-(\d){4}-(\d){4}$/;
    if (!reg.test(value)) {
      callback(new Error("정확한 휴대폰번호를 입력해주세요."));
    } else {
      callback();
    }
  }

  validateUsername(rules: any, value: any, callback: any) {
    const reg = /^[0-9a-zA-Z]{6,12}$/;
    if (reg.test(value)) {
      callback();
    } else {
      callback(new Error("6-12자 이내 영문, 숫자 입력"));
    }
  }

  // validateEmail(rule: any, value: any, callback: any) {
  //   if (value === "") {
  //     callback(new Error("이메일을 입력해 주세요."));
  //   } else if (
  //     !value.match(/^[a-zA-Z0-9_-|.]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/)
  //   ) {
  //     callback(new Error("정확한 이메일 주소를 입력해 주세요."));
  //   } else {
  //     callback();
  //   }
  // }
  validatePass(rules: any, value: any, callback: any) {
    if (this.info_2.password !== this.info_2.again_pass) {
      callback(new Error("비밀번호와 비밀번호 확인이 일치하지 않습니다."));
    } else {
      callback();
    }
  }
  validatePass_8(rules: any, value: any, callback: any) {
    const reg = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/;
    if (reg.test(value)) {
      callback();
    } else {
      callback(new Error("영문,숫자,특수문자 포함 8자 이상 입력"));
    }
  }

  rules = {
    phone: [{ required: true, validator: this.validatePhone }],
    code: [{ required: true, message: "인증번호를 입력해 주세요." }],
    username: [{ required: true, validator: this.validateUsername }],
    password: [{ required: true, validator: this.validatePass_8 }],
    again_pass: [
      {
        required: true,
        validator: this.validatePass,
        trigger: ["change"],
      },
    ],
  };
  info_1 = {
    username: "",
    phone: "",
    code: "",
  };

  step = 1;

  btn_loadding = {
    submit_1: false,
    submit_2: false,
  };

  changePhone(res: any) {
    if (res.length > 8) {
      if (res.slice(3, 4) === "-" && res.slice(8, 9) === "-") {
        this.info_1.phone = res;
      } else if (res.slice(3, 4) === "-") {
        this.info_1.phone = res.slice(0, 8) + "-" + res.slice(8);
      } else {
        this.info_1.phone =
          res.slice(0, 3) + "-" + res.slice(3, 7) + "-" + res.slice(7);
      }
    } else if (res.length > 3) {
      if (res.slice(3, 4) === "-") {
        this.info_1.phone = res;
      } else {
        this.info_1.phone = res.slice(0, 3) + "-" + res.slice(3);
      }
    } else {
      this.info_1.phone = res;
    }
  }

  async submit_1() {
    await (this.$refs["form"] as ElForm).validate();
    this.btn_loadding.submit_1 = true;
    const data = await api_login.check_account(this.info_1).finally(() => {
      this.btn_loadding.submit_1 = false;
    });
    this.info_2 = {
      id: data.id,
      token: data.token,
      password: "",
      again_pass: "",
    };
    this.step = 2;
  }

  info_2 = {
    id: 0,
    token: "",
    password: "",
    again_pass: "",
  };

  async submit_2() {
    await (this.$refs["form"] as ElForm).validate();
    this.btn_loadding.submit_2 = true;
    await api_login
      .edit_user({
        id: this.info_2.id,
        token: this.info_2.token,
        password: this.info_2.password,
      })
      .finally(() => {
        this.btn_loadding.submit_2 = false;
      });
    await this.$alert("성공적으로 변경되었습니다.", "비밀번호 변경", {
      confirmButtonText: "확인",
    }).finally(() => {
      this.$router.push("/login/signin");
    });
  }
  get is_mobile(): boolean {
    return OtherModule.is_mobile;
  }
}
